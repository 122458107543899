// Routes is for the applications workflow definitions.
/*eslint max-lines: ["error", {"max": 350, "skipBlankLines": true}]*/
import React from 'react';

import { RouteBuilder, base } from '@fingermarkglobal/router';

import Logo from './src/assets/images/logo.svg';

import { CustomHomeView } from './src/views/home';
import { CustomBeaconView } from './src/views/beacon';
import { CustomReceiptView } from './src/views/receipt';
import { Warning } from './src/components/stateless/warning';

import { CustomDefaultTemplate } from './src/templates/default';
import { Loading } from '@fingermarkglobal/kfc-styles';

import { version } from './package.json';
import mockSettings from './mock-settings.json';

import {
  RestaurantOrderValidationView,
  RestaurantOrderConfirmationView,
} from '@fingermarkglobal/cringer.components';

const config = {
  ...base,
  home: {
    ...base.home,
    View: () => CustomHomeView({ isShowingCustomBackground: true }),
    config: {
      ...base.home.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
        isCustomBackgroundEnabled: false,
        customBackgroundImage: 'bg-home',
        isShowingCustomBackground: true,
      },
      provider: {
        allowedLanguages: ['rus', 'kaz', 'en'],
      },
    },
  },
  reset: {
    ...base.reset,
    config: {
      ...base.reset.config,
      template: { headerEnabled: false, footerEnabled: false, timeoutEnabled: false },
      provider: { cancelOrder: true },
    },
  },
  beacon: {
    ...base.beacon,
    View: CustomBeaconView,
    config: {
      ...base.beacon.config,
      provider: { primaryPath: '/categories', secondaryPath: '/reset' },
      template: { headerEnabled: false },
    },
  },
  coupon: {
    ...base.coupon,
    config: {
      ...base.coupon.config,
    },
  },
  categories: {
    ...base.categories,
    config: { ...base.categories.config, provider: { nextPath: '/validate' } },
  },
  category: {
    ...base.category,
    config: {
      ...base.category.config,
      provider: { displayDescription: false, showCategoriesHeader: true, nextPath: '/validate' },
    },
  },
  product: {
    ...base.product,
    config: { ...base.product.config, provider: { showCategoriesHeader: true } },
  },
  combo: {
    ...base.combo,
    config: {
      ...base.combo.config,
      provider: { showCategoriesHeader: true, nextPath: '/validate', basketCountPath: '/validate' },
    },
  },
  composed: {
    ...base.composed,
    config: {
      ...base.composed.config,
      provider: { showCategoriesHeader: true, nextPath: '/validate', basketCountPath: '/validate' },
    },
  },
  validate: {
    ...base.validate,
    View: () => (
      <RestaurantOrderValidationView
        image={<Logo data-test="logo" className="absolute top-0 w-auto h-64 mt-32" />}
        isLogoEnabled={true}
      />
    ),
    config: {
      ...base.validate.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  cart: {
    ...base.cart,
    config: {
      ...base.cart.config,
      provider: {
        showCategoriesHeader: true,
        upsellEnabled: true,
      },
    },
  },
  payment: {
    ...base.payment,
    config: {
      ...base.payment.config,
      template: {
        headerEnabled: false,
        footerEnabled: true,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  card: {
    ...base.card,
    config: {
      ...base.card.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  refund: {
    ...base.refund,
    config: {
      ...base.refund.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  confirmation: {
    ...base.confirmation,
    View: () => (
      <RestaurantOrderConfirmationView
        image={<Logo data-test="logo" className="absolute top-0 w-auto h-64 mt-32" />}
        isLogoEnabled={true}
      />
    ),
    config: {
      ...base.confirmation.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
      provider: {
        nextPath: '/receipt',
      },
    },
  },
  receipt: {
    ...base.receipt,
    View: CustomReceiptView,
    config: {
      ...base.receipt.config,
    },
  },
  admin: {
    ...base.admin,
    config: {
      ...base.admin.config,
      provider: { version },
      template: {
        headerEnabled: false,
        footerEnabled: false,
        accessibilityActionEnabled: false,
      },
    },
  },
  notify: {
    ...base.notify,
    config: {
      ...base.notify.config,
      template: { headerEnabled: false, footerEnabled: false, timeoutEnabled: false },
    },
  },
  auth: {
    ...base.auth,
    config: {
      ...base.auth.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        accessibilityActionEnabled: false,
      },
    },
  },
  lock: {
    ...base.lock,
    config: {
      ...base.lock.config,
      provider: { isLogoEnabled: true },
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        accessibilityActionEnabled: false,
      },
    },
  },
  sms: {
    ...base.sms,
    config: { ...base.sms.config, template: { headerEnabled: false, footerEnabled: false } },
  },
  transactions: {
    ...base.transactions,
    config: {
      ...base.transactions.config,
      template: { headerEnabled: false, footerEnabled: false, accessibilityActionEnabled: false },
    },
  },
  loyalty: {
    ...base.loyalty,
    config: {
      ...base.loyalty.config,
    },
  },
  offers: {
    ...base.offers,
    config: {
      ...base.offers.config,
    },
  },
};

const Routes = () => (
  <RouteBuilder
    Template={CustomDefaultTemplate}
    root={{
      overwriteSettings: mockSettings,
      resolverProps: {
        ErrorComponent: Warning,
        Loading: Loading,
        isRetryActive: true,
      },
    }}
    config={config}
  />
);
export { Routes };
